import React from 'react'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */
export default function Index({
  data,
  location,
  pageContext,
}: {
  data: {
    allGhostPost: {
      edges: [
        {
          node: {
            id: string
            html: string
            slug: string
            title: string
            canonical_url?: string
            feature_image?: string
            featured?: boolean
            tags?: [{ name: string }]
            excerpt: string
            primary_author: {
              name: string
              profile_image?: string
            }
          }
        }
      ]
    }
  }
  location: {
    pathname: string
  }
  pageContext: any
}) {
  const posts = data.allGhostPost.edges

  return (
    <>
      <MetaData location={location} />
      <Layout isHome={true}>
        <div className="container">
          <section className="post-feed">
            {posts.map(({ node }) => (
              <PostCard key={node.id} post={node} />
            ))}
          </section>
          <Pagination pageContext={pageContext} />
        </div>
      </Layout>
    </>
  )
}

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: [published_at] }
      limit: $limit
      skip: $skip
      filter: { slug: { ne: "data-schema" } }
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
